import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Architecture from "./pages/home/Architecture";
import TravelAgency from "./pages/home/TravelAgency";
import Expertise from "./pages/home/Expertise";
import Technology from "./pages/home/Technology";
import Solutions from "./pages/home/Solutions";
import Connect from "./pages/home/Connect";
import ScrollToTop from "./helpers/ScrollToTop";

function App() {

  return (
    
      <ScrollToTop>
        <Switch>
          <Route exact path={"/"} component={Architecture} />
          <Route
            exact
            path={"/company"}
            component={TravelAgency}
          />
          <Route
            exact
            path={"/home"}
            component={Architecture}
          />
          <Route
            exact
            path={"/expertise"}
            component={Expertise}
          />
          <Route
            exact
            path={"/technology"}
            component={Technology}
          />
          <Route
            exact
            path={"/solutions"}
            component={Solutions}
          />
          <Route
            exact
            path={"/connect"}
            component={Connect}
          />
          <Route 
          path="*"
          component={Architecture}
          />
        </Switch>
      </ScrollToTop>
  );
}

export default App;
