import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "we_pursue_your": "We Pursue Your,",
          "digital_transformation": "DIGITAL TRANSFORMATION",
          "innovatively":"INNOVATIVELY !",
          "we_think_inside":"We think inside the box,",
          "we_are_dahlia":"We are Dahlia Technologies",
          "introduction":"Introduction",
          "welcome_para_1":"Dahlia Technologies Pvt. Ltd. is a blooming software products and IT services company, founded in 2020 by a team of young professionals and industry specialists from varied backgrounds.",
          "welcome_para_2":"Headquartered in Ahmedabad, India with satellite development centres in Bhubaneswar, Chennai, Noida, Thane, and Vietnam (Ho Chi Minh City) we offer a specialized suite of software products & customized solutions that empower your company to create a connected workforce through digital automation.",
          "language":"Language",
          "home":"Home",
          "hechnology":"Technology",
          "expertise":"Expertise",
          "solutions":"Solutions",
          "company":"Company",
          "connect":"Connect",
          "our_skills":"Our Skills",
          "customisable_and_scalable":"Customisable & Scalable Business Solutions",
          "web_and_app":"Web and App based Software Products",
          "customised_software":"Customised Software Solutions",
          "multi_language_support":"Multi Language Software",
          "our_core_values":"OUR CORE VALUES",
          "beliefs":"Our organizations deeply held beliefs and the fundamental driving forces.",
          "excellence":"Pursuit of Excellence",
          "diversity":"Respect to Diversity",
          "team_work":"Team Work",
          "honesty_and_integrity":"Honesty & Integrity",
          "commitment":"Commitment to Client",
          "challenge":"Challenge to Innovate",
          "about_us":"ABOUT US",
          "something_interesting":"Something Interesting",
          "common_language":"The only common language we speak amongst our diverse team is ‘Technology’.",
          "working_hours":"WORKING HOURS",
          "lines_of_code":"LINES OF CODE",
          "great_minds":"GREAT MINDS",
          "our_expertise":"OUR EXPERTISE",
          "software_and_it":"SOFTWARE PRODUCTS AND IT SOLUTIONS",
          "in_house_agency":"We are a fully in-house technology agency focusing on app and web design and development with clients ranging from start-ups, multi-national technology firms and industry leaders in multiple domains.",
          "business_solutions":"BUSINESS SOLUTIONS",
          "web_development":"WEB DEVELOPMENT",
          "app_development":"APP DEVELOPMENT",
          "smart_solutions":"SMART SOLUTIONS",
          "tech_support":"TECH SUPPORT",
          "fully_responsive":"FULLY RESPONSIVE",
          "business_solutions_para":"We develop smart software applications that are easy to integrate with existing systems with multi platform compatibility, customised to suit your business needs.",
          "web_development_para":"We develop websites and web-based applications which are optimized and responsive to help your business grow.",
          "app_development_para":"We provide mobile application development for platforms such as Android and iOS to kickstart and support your enterprise.",
          "smart_solutions_para":"We build products for businesses by integrating unique content and branding equipped with innovative solutions that are tailored to their specific requirements.",
          "tech_support_para":"Our professional product support team delivers technical support to ensure the smooth running, problem solving, and maintenance of the software.",
          "fully_responsive_para":"Google has made this important since 1998 when it launched. We provide solutions which offer seamless compatibility across devices.",
          "all":"ALL",
          "fintech":"FINTECH", 
          "supply-chain":"SUPPLY-CHAIN", 
          "business":"BUSINESS", 
          "free-app":"FREE-APP",
          "food & service":"FOOD & SERVICE",
          "mts":"My Ticket Solutions",
          "irp":"IRP Finance",
          "asset":"Asset Management",
          "maharaja":"Maharaja App",
          "yp":"Your Point",
          "barcode":"Barcode Loesung",
          "tscm":"TSCM",
          "tnt":"Track & Trace",
          "anand":"Anand App",
          "mts_para":"A ticketing tool for Issue Redressals of  Employees , Clients & Vendors.",
          "irp_para":"A Smart software solution to Streamline the Incoming Purchase Invoices and Account Payable process.",
          "asset_para":"Asset management & tracking software for better visibility & business insights.",
          "maharaja_para":"An all-in-one digital platform that offers Restaurant and Grocery Shop Operators a way to manage their businesses digitally.",
          "yp_para":"Convert first time buyers into repeat customers with 'Yourpoint', a flexible Customer Loyalty Program suitable for any business.",
          "barcode_para":"Barcode technology accessible through Web and Mobile Application for Advanced Cargo Management System.",
          "tscm_para":"An easy to implement and use software solution for your RFQ process.",
          "tnt_para":"Document Management software designed for every kind of Business Communication in Logistics & Shipping Business.",
          "anand_para":"Free, no Data Saving, Easy-To-Use app offering multiple Handy Tools with SOS Feature.",
          "work_together":"Let's Work Together",
          "inside_the_box_together":"We think inside the box, innovatively!",
          "partner":"Partner with Us",
          "boost":"Boost the value of your technology investment by connecting with us and allowing us to develop products and solutions suited to your requirements.",
          "our_team":"Our Team",
          "what_say":"What they have to say",
          "tagline_company":"We think inside the box, innovatively !",
          "development_centres":"Development Centres",
          "sales_offices":"Sales Offices",
          "development_centre":"Development Centre",
          "sales_office":"Sales Office",
          "upcoming_offices":"Upcoming Offices",
          "contact_us":"Contact Us",
          "rights_reserved":"All Rights Reserved.",
          "technology":"TECHNOLOGY",
          "tech_stack":"Our Technology Stack",
          "pursue_digital":"We Persue your Digital Transformation",
          "webdev":"Web Development",
          "appdev":"App Development",
          "bapps":"Business Applications",
          "sss":"Secure Software Solutions",
          "webdev_para":"We develop websites and web-based applications which are optimized and responsive to help your business grow.",
          "appdev_para":"We provide mobile application development for platforms such as Android and iOS to kickstart and support your enterprise.",
          "bapps_para":"The company has a suite of products that are designed to minimize manual processes and interventions, in their respective areas such as procurement, accounting, asset tracking etc. In addition, we also offer customised solutions based on specific client needs.",
          "sss_para":"We provide dual-level security to prevent data theft, damage, and breach to ensure maximum safety and privacy of the database. Our professional product support team deliver technical support to ensure the smooth running, problem solving, and maintenance of the software.",
          "tech_basket_tech":"Our Technology Basket",
          "do_best":"what we do best",
          "meth":"Our Methodologies",
          "agile":"Agile",
          "scrum":"Scrum",
          "kanban":"KanBan",
          "lean":"Lean",
          "prototype":"Prototype",
          "sixsigma":"Six Sigma",
          "agile_para":"Because of its adaptiveness, the Agile methodology is commonly used to deliver more complex projects. It uses six main deliverables to track progress and create the products.",
          "scrum_para":"Scrum is best suited for projects that consist of teams of less than seven people who need a flexible approach to deliver a product or service.",
          "kanban_para":"It works by using a Kanban board to represent the stages of development from the beginning, to the work in progress, to when the work has been completed.",
          "lean_para":"Lean methodology is ideal for any business or organization that is not looking for a process as such, but is interested in transforming how they conduct doing business.",
          "prototype_para":"Prototype is a systems development approach, with a prototype being produced, tested, and corrected if there are any glitches or incomplete issues.",
          "sixsigma_para":"For larger companies and organizations that want to improve quality and efficiency through a data-driven methodology.",
          "expertise":"EXPERTISE",
          "pursue_excellence":"We Pursue Excellence",
          "creative_sol":"Creative Solutions for Out-Of-The-Box Problems",
          "str":"Strategy",
          "inv":"Innovation",
          "prodity":"Productivity",
          "str_para":"The solutions we offer are evolved and developed thinking inside the box with a comprehensive study of the Business Environment. We aim at creating applications that can seamlessly be integrated and implemented, providing full coverage of process computerisation without the requirement of any structural changes in the organization or business processes.",
          "inv_para":"Our team ensures that the delivered software/apps/solutions are innovative, efficient, user-friendly, scalable, and adhere to the most recent design and security standards. The solutions have dual-layer security, SHA1 encryption, SEO/ASO optimization, multilingual support for enterprise-wide implementation, and an optional email/WhatsApp integration.",
          "prod_para":"We want to build diverse teams from around the world. We started with a group of young professionals and industry experts from various backgrounds, and we are now expanding our talent pool to smaller cities in India while providing them with equal opportunities and assembling teams made up of people from different backgrounds in order to foster greater creativity, innovation, and constructive skill-building.",
          "our_serv":"Our Services",
          "offer_we":"what we offer",
          "prod":"PRODUCTS",
          "salient":"SALIENT FEATURES OF OUR PRODUCT",
          "aa":"Simple and easy to use with minimum training",
          "ab":"Customizable to suit Existing IT Landscape",
          "ac":"Multi-Platform and Device Compatibility",
          "ad":"UI Designing with excellent User Experience and Engagement",
          "ae":"Seamless Configuration of Software Solutions with Flexible Deployment options",
          "af":"Handholding Support and Functional Implementation across Geographies",
          "ag":"Integrable to Most of the Standard Accounting Software Products",
          "ah":"Data Analytics, Reporting & Insights for better visibility in Account Payable Process",
          "ai":"Multilingual and In App Chat Feature to Communicate and Collaborate",
          "comp":"The Company",
          "w":"We are",
          "dt":"Dahlia Technologies",
          "x1":"We use the latest technological tools and languages for creating static and dynamic websites, web apps/ software, and mobile apps (Android and IOS) along with providing technical support.",
          "x2":"We are abreast with the latest frontend and backend languages like HTML, Bootstrap, CSS, Core PHP, Codeigniter, Flutter, Android, Swift for IOS, and many more (as of 2021). We use tools such as JIRA, Git, Github for project management and technologies.",
          "x3":"The company has a suite of products that are designed to minimise manual processes and interventions, in their respective areas such as procurement, accounting, asset tracking etc. In addition, we also offer customised solutions based on specific client needs.", 
          "abo":"ABOUT US",
          "v1":"VISION",
          "v2":"MISSION",
          "v3":"HISTORY",
          "v1_para":"We aspire to become the most trusted brand name for our clients, partners, and society with focused efforts through digital innovation and upgrading skills based on a new horizon in the IT and Software industry.",
          "v2_para":"To build a team persistently working on simplifying the business processes with our simple yet innovative software solutions , enabling our customers to build intelligent work culture in their domain.",
          "v3_para":"Dahlia Technologies is a blooming software products and IT services company, founded in 2020 by a team of young professionals and industry specialists from varied backgrounds.",
          "of":"Our Infrastructure",
          "mh":"where the magic happens",
          "offis":"Our Offices",
          "preg":"Our presence around the globe",
          "ct":"Our Core Team",
          "whoweare":"they make us who we are",
          "c1":"Chairman",
          "c2":"Whole Time Director", 
          "c3":"Managing Director",
          "c4":"CTO & Director",
          "c5":"Executive Director",
          "c6":"Managing Director – Vietnam",
          "c1_j":"Sales & Sales Strategy for Europe ",
          "c2_j":"Stakeholder Management and Global Sales ", 
          "c3_j":"Global Sales, Business Promotions & IT Strategy ",
          "c4_j":"Solution Architect & Project Management ",
          "c5_j":"Bringing in Best Practices for IT Product Design & Delivery ",
          "c6_j":"Sales & Sales Strategy for Vietnam ",
          "con":"Connect",
          "git":"Get In Touch",
          "name":"Name",
          "email":"Email",
          "phone":"Your Phone",
          "message":"Message",
          "letst":"Let's Talk",
          "start":"Start a Project",
          "contactus":"Contact Us",
          "para1":"We do not tell you our story. We write it together. Partnering with us means a seat at the table where you will be heard.",
          "product_development":"Product Development",
          "custom_software_solutions":"Custom Software Solutions",
          "outsourcing_capabalities":"Outsourcing Capabilities",
          "support_and_maintainance":"Support & Maintainance",
          "download_corporate_brochure":"Download Corporate Brochure",
          "office_address":"Office Address",
          "email_us":"Email Us",
          "call_us":"Call Us",
          "send_message":"Send Message",
        }
      },
      vn: {
        translation: {
          "we_pursue_your": "CHÚNG TÔI THEO ĐUỔI CÔNG CUỘC",
          "digital_transformation": "KỸ THUẬT SỐ HÓA CỦA BẠN",
          "innovatively":"CÁCH ĐỔI MỚI!",
          "we_think_inside":"CHÚNG TÔI LÀM VIỆC THEO TRUYỀN THỐNG VỚI PHONG,",
          "we_are_dahlia":"Chúng tôi là Công ty CNTT Dahlia",
          "introduction":"Giới thiệu",
          "welcome_para_1":"Công ty TNHH CNTT Dahlia là công ty dịch vụ công nghệ thông tin đang trên đà phát triển mạnh mẽ, được thành lập từ năm 2020 với đội ngũ các chuyên viên và chuyên gia trẻ trong ngành đến từ nhiều nơi khác nhau.",
          "welcome_para_2":"Trụ sở chính tại Ahmedabad, Ấn Độ với các trung tâm phát triển vệ tinh tại Bhubaneswar, Chennai, Noida, Thane và Việt Nam (Thành phố Hồ Chí Minh), chúng tôi cung cấp một bộ chuyên biệt các phần mềm và giải pháp được tùy chỉnh riêng giúp công ty của bạn có được lực lượng lao động được kết nối thông qua tự động hóa kỹ thuật số.",
          "language":"ngôn ngữ",
          "home":"trang chủ",
          "technology":"Công nghệ",
          "expertise":"chuyên môn",
          "solutions":"Các giải pháp",
          "company":"Công ty",
          "connect":"Kết nối",
          "our_skills":"Kỹ năng của chúng tôi",
          "customisable_and_scalable":"Giải pháp kinh doanh có thể tùy chỉnh và mở rộng",
          "web_and_app":"Sản phẩm phần mềm dựa trên Web và Ứng dụng",
          "customised_software":"Giải pháp phần mềm được tùy chỉnh riêng",
          "multi_language_support":"Phần mềm đa ngôn ngữ",
          "our_core_values":"GIÁ TRỊ CỐT LÕI CỦA CHÚNG TÔI",
          "beliefs":"Các tổ chức của chúng tôi có niềm tin sâu sắc và những động lực cơ bản.",
          "excellence":"Theo đuổi sự hoàn mỹ",
          "diversity":"Tôn trọng sự đa dạng",
          "team_work":"Làm việc nhóm",
          "honesty_and_integrity":"Sự trung thực và chính trực",
          "commitment":"Tận tâm với khách hàng",
          "challenge":"Thách thức để phát triển",
          "about_us":"VỀ CHÚNG TÔI",
          "something_interesting":"Một số điều thú vị",
          "common_language":"Thứ ngôn ngữ chung mà chúng tôi sử dụng trong đội ngũ đa dang của mình chính là “Công nghệ”",
          "working_hours":"GIỜ LÀM VIỆC",
          "lines_of_code":"DÒNG CODE",
          "great_minds":"TRÍ TUỆ TUYỆT VỜI",
          "our_expertise":"CHUYÊN MÔN CỦA CHÚNG TÔI",
          "software_and_it":"SẢN PHẨM PHẦN MỀM VÀ GIẢI PHÁP CÔNG NGHỆ",
          "in_house_agency":"Chúng tôi là một công ty công nghệ nội bộ tập trung vào việc thiết kế và phát triển ứng dụng và web với các khách hàng từ những công ty start-ups, tập đoàn công nghệ đa quốc gia đến những người đứng đầu ngành công nghiệp trên nhiều lĩnh vực.",
          "business_solutions":"GIẢI PHÁP KINH DOANH",
          "web_development":"PHÁT TRIỂN WEB",
          "app_development":"PHÁT TRIỂN ỨNG DỤNG",
          "smart_solutions":"GIẢI PHÁP THÔNG MINH",
          "tech_support":"HỖ TRỢ KỸ THUẬT",
          "fully_responsive":"ĐÁP ỨNG ĐẦY ĐỦ",
          "business_solutions_para":"Chúng tôi phát triển các phần mềm ứng dụng thông minh dễ tích hợp vào các hệ thống đã có sẵn với sự thích ứng đa nền tảng và được tùy chỉnh riêng để phù hợp cho nhu cầu của doanh nghiệp.",
          "web_development_para":"Chúng tôi phát triển các trang web và những ứng dụng dựa trên web được tối ưu hóa và phản hồi nhanh để giúp doanh nghiệp của bạn phát triển.",
          "app_development_para":"Chúng tôi cung cấp dịch vụ phát triển ứng dụng di động cho các nền tảng như Android và iOS để bắt đầu và hỗ trợ doanh nghiệp của bạn.",
          "smart_solutions_para":"Chúng tôi xây dựng các sản phẩm dành cho doanh nghiệp bằng cách tích hợp các nội dung và thương hiệu độc đáo vào các giải pháp sáng tạo phù hợp các yêu cầu cụ thể.",
          "tech_support_para":"Đội ngũ hỗ trợ sản phẩm chuyên nghiệp của chúng tôi cung cấp các hỗ trợ kỹ thuật để đảm bảo phần mềm hoạt động trơn tru, giải quyết vấn đề và bảo trì sản phẩm.",
          "fully_responsive_para":"Google đã khiến điều này trở nên quan trọng từ năm 1998 từ khi ra mắt. Chúng tôi cung cấp các giải pháp mang lại khả năng tương thích liền mạch giữa các thiết bị.",
          "all":"TẤT CẢ",
          "fintech":"FINTECH", 
          "supply-chain":"CHUỖI CUNG ỨNG", 
          "business":"KINH DOANH", 
          "free-app":"ỨNG DỤNG MIỄN PHÍ",
          "food & service":"DỊCH VỤ ĂN UỐNG",
          "mts":"Giải pháp Phiếu yêu cầu Cá nhân",
          "irp":"Cổng đăng ký hóa đơn Tài chính",
          "asset":"Trình quản lý Tài sản",
          "maharaja":"Ứng dụng Maharaja",
          "yp":"Your Point",
          "barcode":"Mã vạch Loesung",
          "tscm":"TSCM",
          "tnt":"Ghi dấu và theo dõi",
          "anand":"Ứng dụng Anand",
          "mts_para":"Một công cụ lập phiếu yêu cầu để giải quyết khiếu nại dành cho Nhân viên, Khách hàng & Nhà cung cấp.",
          "irp_para":"Một giải pháp phần mềm thông minh để hợp lý hóa các hóa đơn đến và quy trình thanh toán tài khoản.",
          "asset_para":"Phần mềm quản lý và theo dõi tài sản giúp kiểm soát và quản lý doanh nghiệp tốt hơn.",
          "maharaja_para":"Nền tảng kỹ thuật số tất-cả-trong-một cung cấp cho quản lý Nhà hàng và các Tiệm tạp hóa phương thức quản lý doanh nghiệp theo hướng kỹ thuật số.",
          "yp_para":"Đưa khách hàng mua lần đầu thành khách hàng thân thiết với “Your Point”, giải pháp phần mềm Khách hàng Thân thiết linh hoạt phù hợp với tất cả lĩnh vực kinh doanh.",
          "barcode_para":"Công nghệ Mã vạch dễ truy cập trên Web và Ứng dụng di động dành cho Hệ thống Quản lý Hàng hóa Nâng cao.",
          "tscm_para":"Một giải pháp phần mềm dễ triển khai và sử dụng cho quy trình yêu cầu bảng báo giá của bạn.",
          "tnt_para":"Phần mềm quản lý tài liệu được thiết kế cho tất cả loại hình Giao tiếp Kinh doanh trong ngành Logistics và Vận chuyển.",
          "anand_para":"Ứng dụng miễn phí, không Lưu trữ Dữ liệu, Dễ Sử dụng cung cấp nhiều Công cụ Tiện dụng với Tính năng SOS.",
          "work_together":"HÃY LÀM VIỆC CÙNG NHAU",
          "inside_the_box_together":"CHÚNG TÔI LÀM VIỆC THEO TRUYỀN THỐNG VỚI PHONG CÁCH ĐỔI MỚI!",
          "partner":"Hợp tác với chúng tôi",
          "boost":"Nâng cấp giá trị khoản đầu tư công nghệ của bạn bằng cách kết nối với chúng tôi và cho phép chúng tôi phát triển sản phẩm và giải pháp phù hợp với nhu cầu của bạn.",
          "our_team":"Đội ngũ của chúng tôi",
          "what_say":"HỌ MUỐN NÓI LÊN ĐIỀU GÌ",
          "tagline_company":"Chúng tôi làm việc theo truyền thống với phong cách đổi mới!",
          "development_centres":"Trung tâm phát triển",
          "sales_offices":"Văn phòng kinh doanh",
          "development_centre":"Trung tâm phát triển",
          "sales_office":"Văn phòng kinh doanh",
          "upcoming_offices":"Các văn phòng sắp mở",
          "contact_us":"Liên hệ với chúng tôi",
          "rights_reserved":"Đã đăng ký bản quyền.",
          "technology":"CÔNG NGHỆ",
          "tech_stack":"Các Công nghệ của chúng tôi",
          "pursue_digital":"Chúng tôi theo đuổi công cuộc kỹ thuật số hóa của bạn",
          "webdev":"Phát triển Web",
          "appdev":"Phát triển ứng dụng",
          "bapps":"Ứng dụng kinh doanh",
          "sss":"Giải pháp phần mềm an toàn",
          "webdev_para":"Chúng tôi phát triển các trang web và những ứng dụng dựa trên web được tối ưu hóa và phản hồi nhanh để giúp doanh nghiệp của bạn phát triển.",
          "appdev_para":"Chúng tôi cung cấp dịch vụ phát triển ứng dụng di động cho các nền tảng như Android và iOS để bắt đầu và hỗ trợ doanh nghiệp của bạn.",
          "bapps_para":"Công ty chúng tôi có một bộ các sản phẩm được thiết kế để giảm thiểu các quy trình và can thiệp thủ công, trong các lĩnh vực tương ứng như thu mua, kế toán, theo dõi tài sản v..v... Thêm vào đó, chúng tôi cũng cung cấp các giải pháp được tùy chỉnh dựa theo yêu cầu cụ thể của khách hàng.",
          "sss_para":"Chúng tôi cung cấp bảo mật cấp độ kép để ngăn chặn ăn cắp dữ liệu, hư hại và xâm nhập để đảm bảo sự an toàn và bảo mặt tuyệt đối cho cơ sở dữ liệu. Đội ngũ hỗ trợ sản phẩm chuyên nghiệp của chúng tôi cung cấp hỗ trợ kỹ thuật để đảm bảo phần mềm hoạt động trơn tru, giải quyết vẫn đề cũng như bảo trì sản phẩm.",
          "tech_basket_tech":"Các Sản phẩm Công nghệ của chúng tôi",
          "do_best":" CHÚNG TÔI LÀM GÌ GIỎI NHẤT",
          "meth":"Các Phương pháp của chúng tôi",
          "agile":"Agile",
          "scrum":"Scrum",
          "kanban":"KanBan",
          "lean":"Lean",
          "prototype":"Prototype",
          "sixsigma":"Sáu Sigma",
          "agile_para":"Nhờ tính thích ứng của nó, phương pháp Agile thường được sử dụng để đem lại nhiều dự án phức tạp hơn. Nó dùng đến sáu giao phẩm để theo dõi tiến độ và tạo nên sản phẩm.",
          "scrum_para":"Scrum thích hợp nhất cho các dự án mà trong đó gồm có các đội ngũ ít hơn 7 người, những người cần cách tiếp cận linh hoạt để cung cấp sản phẩm hoặc dịch vụ.",
          "kanban_para":"Nó hoạt động bằng cách sử dụng bảng KanBan để trình bày các giai đoạn phát triển từ lúc bắt đầu, đến giai đoạn đang tiến hành, đến khi công việc đã hoàn thành.",
          "lean_para":"Phương pháp Lean lý tưởng cho bất kỳ doanh nghiệp hay tổ chức nào đang tìm kiếm một quy trình tinh gọn, nhưng cũng quan tâm đến việc chuyển đổi việc họ tiến hành kinh doanh.",
          "prototype_para":"Prototype là một phương cách phát triển hệ thống, với một nguyên mẫu được sản xuất, thử nghiệm và tinh chỉnh nếu có xảy ra bất kỳ vấn đề trục trặc hoặc chưa hoàn thiện.",
          "sixsigma_para":"Dành cho các công ty và tổ chức lớn hơn muốn nâng cao chất lượng và hiệu quả thông qua phương pháp dựa trên dữ liệu.",
          "expertise":"CHUYÊN MÔN",
          "pursue_excellence":"Chúng tôi theo đuổi sự Hoàn mỹ",
          "creative_sol":"CÁC GIẢI PHÁP SÁNG TẠO CHO CÁC VẤN ĐỀ KHÁC BIỆT",
          "str":"Chiến lược",
          "inv":"Sự đổi mới",
          "prodity":"Năng suất",
          "str_para":"Các giải pháp chúng tôi đưa ra đều được phát triển và nâng cao từ các phương thức truyền thống cùng với sự nghiên cứu toàn diện về Môi Trường Kinh Doanh. Chúng tôi hướng đến việc tạo nên các ứng dụng có thể được tích hợp và triển khai liền mạch, đem lại sự bao quát toàn diện cho việc máy tính hóa quy trình mà không yêu cầu bất kỳ sự thay đổi cấu trúc nào trong quy trình tổ chức hay kinh doanh.",
          "inv_para":"Đội ngũ của chúng tôi đảm bảo rằng phần mềm/ứng dụng/giải pháp được phân phối có đầy đủ sự đổi mới, hiệu quả, thân thiện với người dùng, có thể mở rộng và tuân thủ các quy định thiết kế và bảo mật mới nhất. Các giải pháp đều có lớp bảo mật kép, mã hóa SHA1, tối ưu hóa SEO/ASO, hỗ trợ đa ngôn ngữ cho việc triển khai toàn doanh nghiệp, và tùy chọn tích hợp email/WhatsApp.",
          "prod_para":"Chúng tôi muốn xây dựng nhiều đội ngũ đa dạng đến từ kháp nơi trên thế giới. Chúng tôi bắt đầu từ những chuyên viên và chuyên gia trẻ trong ngành đến từ nhiều nơi khác nhau, và chúng tôi vẫn đang mở rộng nhóm ứng viên tiềm năng đến các thành phố nhỏ hơn ở Ấn Độ đồng thời mang đến cho họ cơ hội công bằng và tập hợp các đội ngũ với các thành viên có xuất thân khác nhau với mục đích thúc đẩy khả năng sáng tạo, đổi mới và xây dựng kỹ năng tốt hơn.",
          "our_serv":"Dịch vụ cùa chúng tôi",
          "offer_we":"chúng tôi đem đến những gì",
          "prod":"CÁC SẢN PHẨM",
          "salient":"CÁC ĐẶC TRƯNG QUAN TRỌNG TRONG SẢN PHẨM CỦA CHÚNG TÔI",
          "aa":"Đơn giản và dễ sử dụng mà không cần hướng dẫn nhiều.",
          "ab":"Được điều chỉnh để phù hợp với quang cảnh CNTT hiện tại.",
          "ac":"Tương thích đa nền tảng và thiết bị.",
          "ad":"Thiết kế UI mang lại trải nghiệm người dùng và tương tác tuyệt hảo.",
          "ae":"Cấu hình liền mạch của các Giải pháp Phần mềm với các tùy chọn triển khai linh hoạt.",
          "af":"Hỗ trợ và triển khai chức năng tận tình không màng địa lý.",
          "ag":"Có thể tích hợp vào hầu hết các sản phẩm phần mềm kế toán tiêu chuẩn.",
          "ah":"Phân tích dữ liệu, báo cáo và theo dõi để kiểm soát tốt hơn trong quy trình chi trả.",
          "ai":"Tính năng đa ngôn ngữ và trò chuyện trong ứng dụng để giao tiếp và hợp tác.",
          "comp":"VỀ CÔNG TY",
          "w":"CHÚNG TÔI LÀ",
          "dt":"CÔNG TY CNTT DAHLIAs",
          "x1":"Chúng tôi sử dụng các công cụ và ngôn ngữ công nghệ mới nhất để tạo ra các trang web tĩnh và động, ứng dụng web/ phần mềm, và ứng dụng di động (Android và iOS) cùng với việc cung cấp hỗ trợ kỹ thuật.",
          "x2":"Chúng tôi cập nhật các ngôn ngữ giao diện người dùng và phụ trợ mới nhất như HTML Bootstrap, CSS, Core PHP, Codeigniter, Flutter, Android, Swift cho IOS, và hơn thế nữa (tính đến 2021). Chúng tôi sử dụng các công cụ như JIRA, Git, Github để quản lý dự án và công nghệ.",
          "x3":"Công ty chúng tôi có một bộ các sản phẩm được thiết kế để giảm thiểu các quy trình và can thiệp thủ công, trong các lĩnh vực tương ứng như thu mua, kế toán, theo dõi tài sản v..v... Thêm vào đó, chúng tôi cũng cung cấp các giải pháp được tùy chỉnh dựa theo yêu cầu cụ thể của khách hàng.",
          "abo":"VỀ CHÚNG TÔI",
          "v1":"TẦM NHÌN",
          "v2":"SỨ MỆNH",
          "v3":"LỊCH SỬ",
          "v1_para":"Chúng tôi mong muốn trở thành thương hiệu được tin cậy nhất từ khách hàng, đối tác và xã hội với những nỗ lực tập trung trong việc đổi mới công nghệ và nâng cấp kỹ năng dựa vào chân trời mới trong lĩnh vực IT và Phần mềm.",
          "v2_para":"Để xây dựng nên một đội ngũ kiên trì thực hiện công việc đơn giản hóa các quy trình kinh doanh với các giải pháp phần mềm đơn giản nhưng cải tiến của chúng tôi, giúp khách hàng xây dựng nên văn hóa làm việc thông minh tại doanh nghiệp của họ.",
          "v3_para":"Dahlia là công ty dịch vụ công nghệ thông tin đang trên đà phát triển mạnh mẽ, được thành lập từ năm 2020 với đội ngũ các chuyên viên và chuyên gia trẻ trong ngành đến từ nhiều nơi khác nhau.",
          "of":"Cơ sở hạ tầng của chúng tôi",
          "mh":"NƠI PHÉP THUẬT HIỆN HÌNH", 
          "offis":"Các Văn phòng đại diện",
          "preg":"SỰ HIỆN DIỆN CỦA CHÚNG TÔI TRÊN KHẮP THẾ GIỚI", 
          "ct":"Đội ngũ nòng cốt",
          "whoweare":"CHÍNH HỌ TẠO NÊN CHÚNG TÔI BÂY GIỜ",
          "c1":"Chủ tịch ",
          "c2":"Giám đốc toàn thời gian ", 
          "c3":"Giám đốc Điều hành ",
          "c4":"CTO & Giám đốc ",
          "c5":"Giám đốc Điều hành ",
          "c6":"Giám đốc Điều hành – Việt Nam",
          "c1_j":"Kinh doanh và chiến lược kinh doanh tại châu Âu ",
          "c2_j":"Quản lý cổ đông và Kinh doanh Toàn cầu ", 
          "c3_j":"Kinh doanh Toàn cầu, Xúc tiến Kinh doanh và chiến lược IT ",
          "c4_j":"Kiến trúc Giải pháp và Quản lý Dự án ",
          "c5_j":"Đưa vào các Phương pháp Hay nhất để Thiết kế và Điều phối Sản phẩm IT ",
          "c6_j":"Kinh doanh và Chiến lược Kinh doanh cho Việt Nam ",
          "con":"KẾT NỐI",
          "git":"LIÊN HỆ",
          "name":"Tên",
          "email":"Email",
          "phone":"Số điện thoại",
          "message":"Tin nhắn",
          "letst":"Hãy trò chuyện nào",
          "start":"Bắt đầu một Dự án",
          "contactus":"Contact Us",
          "para1":"Chúng tôi không kể cho bạn câu chuyện của mình. Chúng ta viết nên nó cùng nhau. Hợp tác với chúng tôi nghĩa là bạn sẽ được lắng nghe.",
          "product_development":"Phát triển sản phẩm",
          "custom_software_solutions":"Giải pháp phần mềm tùy chỉnh",
          "outsourcing_capabalities":"Khả năng thuê ngoài",
          "support_and_maintainance":"Hỗ trợ & Bảo trì",
          "download_corporate_brochure":"Tải Brochure công ty",
          "office_address":"Dịa chỉ văn phòng",
          "email_us":"Gửi email cho chúng tôi",
          "call_us":"Gọi cho chúng tôi",
          "send_message":"Gửi tin nhắn",
        }
      }
    },
    lng: "vn", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;