import React, { forwardRef } from 'react';
import HeadingSection1 from '../HeadingSection/HeaderSection1';

const Welcome = forwardRef(( {title, tagline, children}, ref ) => (
    <section ref={ref} className="first-ico-box" id="about">
    <div className="container">
      <div className="row">
          <HeadingSection1 title={title} tagline={tagline}>
          </HeadingSection1>
      </div>
    </div>
  </section>
));

export default Welcome;