import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import PageTitleExpertise from "../../components/PageTitle/PageTitleExpertise";
import ServicesArchitecture from "../../components/OurServices/ServicesArchitecture";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useLax} from "../../helpers/use-lax";
import WhoWeAreSix from "../../components/WhoWeAre/WhoWeAreSix";
import FooterOne from "../../components/Footer/FooterOne";
import { useTranslation } from "react-i18next";

const Expertise = () => {

  const { t } = useTranslation();

    const service = useRef();

    useLax();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
  <Loader>
    <HeaderTwo />
    <PageTitleExpertise
        title={t('expertise')}
      />
      <WhoWeAreSix
        title={t('pursue_excellence')}
        tagline={t('creative_sol')}
      />
      <ServicesArchitecture
        title={t('our_serv')}
        tagline={t('offer_we')}
      />
    <FooterOne />
  </Loader>
)};

export default Expertise;
