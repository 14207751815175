import React from "react";
import WelcomeSubsection from "./WelcomeSubsection";
import WelcomeFeatures from "./WelcomeFeatures";
import HeadingThree from "../../components/HeadingSection/HeadingThree";
import OurSkillsOne from "../OurSkills/OurSkillsOne";
import image from "../../assets/images/258.jpg";
import TeamSlider1 from "../Team/TeamSlider1";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";

const WelcomeTwo = ({ toggler, handleToggler }) => 
{

  const { t } = useTranslation();

return (
  <section className="dark-bg pt-0 pb-0">
    <WelcomeSubsection
      title={t('we_are_dahlia')}
      tagline={t("introduction")}
      image={image}
      textButton={t("download_corporate_brochure")}
      toggler={toggler}
      handleToggler={handleToggler}
    >
      {t('welcome_para_1')}<br />
      <br />{t('welcome_para_2')}
    </WelcomeSubsection>
    <div className="container-fluid">
      <div className="row row-flex flex-center">
        <HeadingThree
          title={t('our_skills')}
        >
          <OurSkillsOne classAppend="cardo-font" font="cardo-font" />
        </HeadingThree>
        <div className="col-md-4 col-sm-12 team-member-wrapper">
          <div className="col-inner">
            <TeamSlider1 />
          </div>
        </div>
        <WelcomeFeatures
          title={t("our_core_values")}
          tagline={t("beliefs")}
        />
      </div>
    </div>
  </section>
)};

export default WelcomeTwo;
