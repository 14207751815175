import React from "react";
import teamData from "../../data/Team/team-data.json";
import Icofont from "react-icofont";
import Welcome from "../../components/WelcomeSection/Welcome";
import { useTranslation } from "react-i18next";

const OurTeamThree = () => 
{
  const { t } = useTranslation();
return(
  <>
    <Welcome title={t('ct')} tagline={t('whoweare')}></Welcome>
    <section className="pt-0">
      <div className="container">
        
        <div class="d-lg-none">
        <div className="row startup-member">
          <div className="col-md-1">
            
          </div>
          {teamData.map((member) => (
            <div className="col-md-2 col-sm-6 " key={member.id}>
              <div className="member text-center mt-3">
                <div className="team-member-container">
                  <img
                    src={require("../../assets/images/" + member.image)}
                    className="img-fluid px-5"
                    alt="team-01"
                  />
                </div>
                  <h5 className="member-title">
                    {member.name}
                  </h5>
                  <h6 className="member-title">
                    {t(member.role)}
                  </h6>
                  <p className="member-subtitle">
                    {t(member.quote)}
                  </p>
              </div>
            </div>
          ))}
        </div>
        </div>
        <div class="d-none d-lg-block">
        <div className="d-flex text-align-centre-css startup-member">
        <div className="col-md"></div>
          {teamData.map((member) => (
            <div className="col-md-3 col-sm-6 p-4" key={member.id}>
              <div className="member text-center ">
                <div className="team-member-container">
                  <img
                    src={require("../../assets/images/" + member.image)}
                    className="img-fluid"
                    alt="team-01"
                  />
                </div>
                  <h5 className="member-title">
                    {member.name}
                  </h5>
                  <h6 className="member-title">
                    {t(member.role)}
                  </h6>
                  <p className="member-subtitle">
                    {t(member.quote)}
                  </p>
              </div>
            </div>
          ))}
          <div className="col-md"></div>
        </div>
        </div>
      </div>
    </section>
  </>
)};

export default OurTeamThree;
