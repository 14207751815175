import React, { useState, useEffect, useCallback } from "react";
import Scrollspy from "react-scrollspy";
import Icofont from "react-icofont";
import dataNav from "../../data/Navbar/nav-creativeone-data.json";
import MainLogo from "../MainLogo";
import { Link, useHistory } from "react-router-dom";
import Select from 'react-select';

import { IoLanguageOutline } from 'react-icons/io5';

import i18next from "i18next";
import { useTranslation } from "react-i18next";


const HeaderTwo = ({ social, scrollToSection }) => {

  const { i18n, t } = useTranslation();

  const [fixed, setFixed] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [toggle, setToggle] = useState(false);
  const history = useHistory();

  const showMenu = () => {
    setCollapse(!collapse);
    const menu = document.getElementById("navbar-menu");
    collapse ? menu.classList.remove("in") : menu.classList.add("in");
  };

  const translateLanguage = (code) => {
      localStorage.setItem("code",JSON.stringify(code))
			i18n.changeLanguage(code);
      console.log("changed");
	}

  const navbarButtonClicked = () => {
    setToggle(!toggle);
  }

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 34) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <nav
        className={
          "navbar-scrollspy navbar navbar-light navbar-expand-lg navbar-fixed white bootsnav on no-full " +
          (fixed ? "" : "navbar-transparent")
        }
        data-minus-value-desktop="70"
        data-minus-value-mobile="55"
        data-speed="1000"
      >
        <div className="container">
          <button
            type="button"
            className={"navbar-toggler " + (collapse ? "collapsed" : "")}
            data-toggle="dropdown"
            data-target="#navbar-menu"
            onClick={showMenu}
          >
            <Icofont icon="navigation-menu" />
          </button>
          <MainLogo showMenu={showMenu} />
          <div className="collapse navbar-collapse" id="navbar-menu">
            <Scrollspy
              items={[
                "home",
                "solutions",
                "expertise",
                "technology",
                "company",
                "connect",
              ]}
              className="nav navbar-nav navbar-right nav-scrollspy-onepage"
              data-in="fadeInLeft"
            >
              {dataNav.map((item) => (
                <li className="scroll navbar-item" key={item.id}>
                  <Link
                    className="nav-link"
                    to={item.link}
                    onClick={(e) => history.push(`/${item.link}`)}
                  >
                    {t(item.title)}
                  </Link>
                </li>
              ))}
            <li className="scroll navbar-item">
                  <Link
                    onClick={(e) => navbarButtonClicked()}
                    className="nav-link"
                  ><IoLanguageOutline />{t("language")}
                  {toggle ? (
                    <ul className="mt-3 setting-width-in-desktop-options dropdown-menu dropdown-item animated" role="menu">
                    <li className="dropdown">
                      <Link
                        className="dropdown-item"
                        data-toggle="dropdown"
                      ><span onClick={(e)=>translateLanguage('en')} className="set-text-size-to-smaller">
                        English</span><span class="mx-2 fi fi-gb"></span>
                      </Link>
                    </li>
                    <li className="dropdown">
                      <Link
                        className="dropdown-item"
                        data-toggle="dropdown"
                      ><span onClick={(e)=>translateLanguage('vn')} className="set-text-size-to-smaller">
                        Tiếng Việt</span><span class="mx-2 fi fi-vn"></span>
                      </Link>
                    </li>
                </ul>
                  ) : ("")}
                  </Link>
                  
                </li>
              
            </Scrollspy>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderTwo;
