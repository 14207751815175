import React from "react";
import Loader from "./../../components/Loader/Loader";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import HeaderTwo from "../../components/Header/HeaderTwo";
import OurTeamThree from "../../components/Team/OurTeamThree";
import OurTeamSeven from "../../components/Team/OurTeamSeven";
import WelcomeTravel from "../../components/WelcomeSection/TravelAgency/WelcomeTravel";
import FooterOne from "../../components/Footer/FooterOne";
import Map from "../../assets/images/map3.png";
import Map2 from "../../assets/images/map2.png";
import Welcome from "../../components/WelcomeSection/Welcome22";
import BlogSlider from "../../components/Blog/BlogSlider";
import BlogSlider1 from "../../components/Blog/BlogSlider1";
import { useTranslation } from "react-i18next";

const TravelAgency = () => 
{
  const { t } = useTranslation();

return(
  <Loader>

    <HeaderTwo />

    <PageTitleAbout title={t('comp')} />

    <WelcomeTravel />

    <OurTeamSeven />

    <Welcome title={t('offis')} tagline={t('preg')}></Welcome>

    <BlogSlider />

    <BlogSlider1 />

    <OurTeamThree />

    <FooterOne />
    
  </Loader>
)};

export default TravelAgency;
