import React from "react";
import { useTranslation } from "react-i18next";
// import ReactWow from "react-wow";
import Icofont from "react-icofont";
import { FaPaperclip } from 'react-icons/fa';
import {Link} from "react-router-dom";
import appstoreicon from "../../assets/images/shop/apple.png";
import playstoreicon from "../../assets/images/shop/google.png";

const PortfolioItem = ({
  title,
  category,
  image,
  links,
  google,
  apple,
  space,
  groups,
  type,
  columns,
  openLightbox,
}) => {

  const redirectapple = (apple) => {
    console.log("Redirect to apple store");
  }

  const redirectgoogle = (google) => {
    console.log("Redirect to google store");
  }

  const { t } = useTranslation();

  return (
    // <ReactWow animation="fadeIn">
      <div
        className={
          "portfolio-item col-md-" + 12/columns +
          (type === "product" ? " portfolio-masonry-item " : " ") +
          (space === "true" ? "gutter-space" : "no-gutter")
        }
        data-wow-delay="0.2s"
        data-groups={
          groups ? "[" + groups.map((val) => `"${val}"`) + "]" : null
        }
      >
        {/* <ReactWow animation="fadeIn"> */}
          <div
            className={
              "portfolio gallery-image-hover " +
              (type === "masonry" ? "portfolio-masonry-item" : "")
            }
          >
            <div className="dark-overlay"></div>
            <img src={require("../../assets/images/" + image)} alt="" />
            <div className="portfolio-wrap">
              <div className="portfolio-description">
              <a className="links m-3">{t(title)}</a></div>
              <div className="portfolio-description1">
              <a className="links1">{t(category)}</a></div>
              
              {/* <ul className="portfolio-details">
                <li>
                  {links !== "" ? (
                    <>
                  <p className="mt-30">
                  <a href={links} target="_blank" className="btn btn-sm btn-color">
                    Visit Website
                  </a>
                </p>
                </>
                  ) : ("")}
                </li><br />
                <li>
                <div className="d-flex align-items-centre">
                {apple !== null ? (
                    <>
                    <span onClick={() => redirectapple(apple)}>
                  <img src={appstoreicon} className="setting-download-app-icon-size" alt="" />
                  </span>
                </>
                  ) : ("")}
                  {google !== null ? (
                    <>
                    <span onClick={() => redirectgoogle(google)}>
                  <img src={playstoreicon} className="setting-download-app-icon-size" alt="" />
                  </span>
                </>
                  ) : ("")}
                </div>
                </li>
              </ul> */}
            </div>
          </div>
        {/* </ReactWow> */}
      </div>
    // </ReactWow>
  );
};

export default PortfolioItem;
