import React from "react";
import Icofont from "react-icofont";
import { useTranslation } from "react-i18next";

const WelcomeItem = () => 
{
  const { t } = useTranslation();
return(
  <>
  <div className="d-lg-none">
    <div className="white-bg clearfix pt-10 pb-10 pr-20 relative">
    <div className="travel-bg-left bg-flex col-md-6"></div>
    <div className="col-about-right col-md-6 offset-md-6">
      <h2 className="text-uppercase font-700">
        {t('w')}
        <br />
        {t('dt')}
      </h2>
      <div className="mt-30">
        <p>
        {t('x1')}
        </p>
      </div>
      <div className="mt-30">
        <p>
        {t('x2')}
        </p>
      </div>
      <div className="mt-30">
      <p>
      {t('x3')}
        </p>
      </div>
    </div>
  </div>
  </div>
  <div className="d-none d-lg-block">
    <div className="white-bg clearfix pt-100 pb-100 relative">
    <div className="travel-bg-left bg-flex col-md-6"></div>
    <div className="col-about-right col-md-6 offset-md-6">
      <h2 className="text-uppercase font-700">
      {t('w')}
        <br />
        {t('dt')}
      </h2>
      <div className="mt-30">
        <p>
       {t('x1')}
        </p>
      </div>
      <div className="mt-30">
        <p>
        {t('x2')}
        </p>
      </div>
      <div className="mt-30">
      <p>
      {t('x3')}
        </p>
      </div>
    </div>
  </div>
  </div></>
)};

export default WelcomeItem;
