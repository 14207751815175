import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import App from "./App";
import "./i18n";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

ReactDOM.render(
<Router><App /></Router>, document.getElementById("main"));
