import React from "react";
import parse from "html-react-parser";
import HeadingSection from "../HeadingSection/HeadingSection";
import {Link} from "react-router-dom";

import img from "../../assets/images/background/123456.jpg"

const CTAFour = ({ title, tagline, textButton, children }) => (
  <>
  <div class="d-md-none d-lg-none">
  <section className="fixed-bg"
  style={{backgroundImage: `url(${img})`}}>
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center white-color offset-md-3">
          {/* <ReactWOW animation="fadeTop" delay="0.1s"> */}
          <HeadingSection title={title} />
          {/* </ReactWOW> */}
          {/* <ReactWOW animation="fadeTop" delay="0.2s"> */}
          <h4 className="text-uppercase dark-color">{tagline}</h4>
          {/* </ReactWOW> */}
          {/* <ReactWOW animation="fadeTop" delay="0.3s"> */}
            <p className="font-20px mt-20 line-height-30">
              {children ? parse(children) : ""}
            </p>
          {/* </ReactWOW> */}
          {/* <ReactWOW animation="fadeTop" delay="0.4s"> */}
            <p className="mt-30">
              <Link to="/connect" className="btn btn-color btn-circle">
                {textButton}
              </Link>
            </p>
          {/* </ReactWOW> */}
        </div>
      </div>
    </div>
  </section>
  </div>
  <div class="d-none d-md-block d-lg-block">
  <section className="parallax-bg-117 fixed-bg">
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center white-color offset-md-3">
          {/* <ReactWOW animation="fadeTop" delay="0.1s"> */}
          <HeadingSection title={title} />
          {/* </ReactWOW> */}
          {/* <ReactWOW animation="fadeTop" delay="0.2s"> */}
          <h4 className="text-uppercase dark-color">{tagline}</h4>
          {/* </ReactWOW> */}
          {/* <ReactWOW animation="fadeTop" delay="0.3s"> */}
            <p className="font-20px mt-20 line-height-30">
              {children ? parse(children) : ""}
            </p>
          {/* </ReactWOW> */}
          {/* <ReactWOW animation="fadeTop" delay="0.4s"> */}
            <p className="mt-30">
              <Link to="/connect" className="btn btn-color btn-circle">
                {textButton}
              </Link>
            </p>
          {/* </ReactWOW> */}
        </div>
      </div>
    </div>
  </section>
  </div>
  </>
);

export default CTAFour;
