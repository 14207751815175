import React from "react";
import logoFooter from "../../assets/images/logo_w.png";
import FooterCopyright from "./FooterCopyright";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterOne = () => 
{
  const { t } = useTranslation();
return (
  <>
    <footer className="footer" id="footer-fixed">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="widget widget-text">
                <div className="logo logo-footer">
                  <Link to={process.env.PUBLIC_URL}>
                    <img
                      className="logo logo-display"
                      src={logoFooter}
                      alt=""
                    />
                  </Link>
                </div>
                <p>
                  {t('tagline_company')}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-2">
              <div className="widget widget-links">
                <h5 className="widget-title">{t('development_centres')}</h5>
                <ul>
                  <li>
                    <Link style={{"cursor":"context-menu"}} to="#!">Ahmedabad ( IND )</Link>
                  </li>
                  <li>
                    <Link style={{"cursor":"context-menu"}}  to="#!">Bhubaneswar ( IND )</Link>
                  </li>
                  <li>
                    <Link style={{"cursor":"context-menu"}}  to="#!">Chennai ( IND )</Link>
                  </li>
                  <li>
                    <Link style={{"cursor":"context-menu"}}  to="/home">Ho Chi Minh ( VNM )</Link>
                  </li>
                  {/* <li>
                    <Link to="#!">Chennai</Link>
                  </li>
                  <li>
                    <Link to="#!">Kolkata</Link>
                  </li>
                  <li>
                    <Link to="#!">Noida</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-2">
              <div className="widget widget-links">
                <h5 className="widget-title">{t('sales_offices')}</h5>
                <ul>
                <li>
                    <Link style={{"cursor":"context-menu"}}  to="#!">Ahmedabad ( IND )</Link>
                  </li>
                <li>
                    <Link style={{"cursor":"context-menu"}}  to="#!">Thane ( IND )</Link>
                  </li>
                <li>
                    <Link style={{"cursor":"context-menu"}}  to="/home">Ho Chi Minh ( VNM )</Link>
                  </li>
                <br />
                <li>
                <h5 className="widget-title">{t('upcoming_offices')}</h5>
                  </li>
                <li>
                    <Link style={{"cursor":"context-menu"}}  to="#!">Dubai ( UAE )</Link>
                  </li>
                  <li>
                    <Link style={{"cursor":"context-menu"}}  to="#!">Singapore ( SGP )</Link>
                  </li>  
                {/* <li>
                    <Link to="#!">Germany</Link>
                  </li>
                <li>
                    <Link to="#!">Kenya</Link>
                  </li>
                <li>
                    <Link to="#!">Singapore</Link>
                  </li>
                <li>
                    <Link to="#!">Vietnam</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="widget widget-text widget-links">
                <h5 className="widget-title">{t('contact_us')}</h5>
                <ul>
              
                  <li>
                    <i className="icofont icofont-google-map"></i>
                    <Link to={"/"}>
                    # 809A Saigon Paragon Building,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3 Nguyễn Lương Bằng, Tân Phú, Quận 7,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Thành phố Hồ Chí Minh, Vietnam, 700000.
                    </Link>
                  </li>
                  <li>
                    <i className="icofont icofont-iphone"></i>
                    <Link to={"/"}>+84 708683407</Link>
                  </li>
                  <li>
                    <i className="icofont icofont-mail"></i>
                    <Link to='javascript:void(0)'
      onClick={() => window.location = 'mailto:sales-vn@dahlia.tech'}>
                    sales-vn@dahlia.tech
                    </Link>
                  </li>
                  <li>
                    <i className="icofont icofont-globe"></i>
                    <Link to={"/"}>www.vn.dahlia.tech</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCopyright />
    </footer>
    <div className="footer-height" style={{ height: "540px" }}></div>
  </>
)};

export default FooterOne;
