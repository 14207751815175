import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import PageTitleTechnology from "../../components/PageTitle/PageTitleTechnology";
import Portfolio from "../../components/Portfolio/Portfolio2";
import HeaderTwo from "../../components/Header/HeaderTwo";
import { useLax } from "../../helpers/use-lax";
import AboutAppStyle from "../../components/AboutUs/AboutAppStyle";
import FooterOne from "../../components/Footer/FooterOne";
import OurServices1 from "../../components/OurServices/OurServices1";
import serviceOneImg from "../../assets/images/technologyleft1.jpg";
import { useTranslation } from "react-i18next";

const Technology = () => {

  const { t } = useTranslation();
	
  const service = useRef();
  const feature = useRef();

  useLax();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>

      <HeaderTwo />

      <PageTitleTechnology title={t('technology')} />

      <OurServices1
        title={t('tech_stack')}
        tagline={t('pursue_digital')}
        serviceImg={serviceOneImg}
      />

      <section className="pt-100 pt-100">
        <Portfolio filter="true" columns="6" layout="box" space="true" />
      </section>

      <AboutAppStyle ref={feature} />

      <FooterOne />

    </Loader>
  );
};

export default Technology;
