import React, { useEffect, useRef, useState, forwardRef } from "react";
import dataPortfolio from "../../data/Portfolio/technology-stack.json";
import PortfolioFilter from "../../elements/Portfolio/PortfolioFilter";
import PortfolioItem from "./PortfolitoItem2";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import Welcome from "../../components/WelcomeSection/Welcome";
import Shuffle from "shufflejs";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Portfolio = ()=>{
  
  const { t } = useTranslation();

    return (
        <>
      <Welcome title={t('tech_basket_tech')} tagline={t('do_best')}>
      </Welcome>  
      <section
        className={"pb-0"}
        id="work"
      >
        <div className={"container"}>
          <div className="row">
            <div className={"container text-center"}>

              <div
                id="portfolio-grid"
                className="hover-two row"
              >
                {dataPortfolio.map((item) => (
                      <PortfolioItem
                        key={item.id}
                        title={item.title}
                        category={item.category}
                        image={item.image}
                        groups={item.groups}
                        space={"true"}
                        columns={6}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
        <br /><br /><br />
      </section>
      </>
    );
  };

export default Portfolio;
