import React from "react";
// import ReactWow from "react-wow";
import Icofont from "react-icofont";

const PortfolioItem = ({
  title,
  category,
  image,
  links,
  space,
  groups,
  type,
  columns,
  openLightbox,
}) => {
  return (
    // <ReactWow animation="fadeIn">
      <div
        className={
          "portfolio-item col-md-" + 12/columns +
          (type === "product" ? " portfolio-masonry-item " : " ") +
          (space === "true" ? "gutter-space" : "no-gutter")
        }
        data-wow-delay="0.2s"
        data-groups={
          groups ? "[" + groups.map((val) => `"${val}"`) + "]" : null
        }
      >
        {/* <ReactWow animation="fadeIn"> */}
          <div
            className={
              "portfolio gallery-image-hover " +
              (type === "masonry" ? "portfolio-masonry-item" : "")
            }
          >
            <img src={require("../../assets/images/" + image)} alt="" />
           
          </div>
        {/* </ReactWow> */}
      </div>
    // </ReactWow>
  );
};

export default PortfolioItem;
