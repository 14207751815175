import React from "react";
import dataServices1 from "../../data/Services/our-services-two5.json";
import dataServices2 from "../../data/Services/our-services-two6.json";
import FeatureBoxOne from "../../elements/FeatureBox/FeatureBoxOne";

import { useTranslation } from "react-i18next";

const WelcomeFeatures = ({ title, tagline }) => 
{
  const { t } = useTranslation();
return (
  <div className="col-md-4 col-sm-12 blue-bg">
    <div className="col-inner spacer white-color">
      <h2
        className="cardo-font font-500"
        data-aos={"fade-up"}
        data-aos-delay={100}
        data-aos-duration={1000}
      >
        {title}
      </h2>
      <h4
        className="cardo-font dark-color"
        data-aos={"fade-up"}
        data-aos-delay={100}
        data-aos-duration={1000}
      >
        {tagline}
      </h4>
      <div className="col-ms-12 d-flex">
      <div className="col-md-6 pt-40 pb-20">
        {dataServices1.map((feature, i) => (
          <FeatureBoxOne
            key={feature.id}
            title={feature.title}
            icon={feature.icon}
            index={i}
          >
            {feature.text}
          </FeatureBoxOne>
        ))}
      </div>
      <div className="col-md-6 pt-40 pb-20">
        {dataServices2.map((feature, i) => (
          <FeatureBoxOne
            key={feature.id}
            title={feature.title}
            icon={feature.icon}
            index={i}
          >
            {feature.text}
          </FeatureBoxOne>
        ))}
      </div></div>
    </div>
  </div>
)};

export default WelcomeFeatures;
