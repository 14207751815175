import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import PageTitleConnect from "../../components/PageTitle/PageTitleConnect";
import HeaderTwo from "../../components/Header/HeaderTwo";
import {useLax} from "../../helpers/use-lax";
import FooterOne from "../../components/Footer/FooterOne";
import CTAThree from "../../components/CTA/CTAThree";
import ContactFour from "../../components/ContactUs/ContactFour";
import { useTranslation } from "react-i18next";

const Connect = () => {

  const { t } = useTranslation();

    const contact = useRef();

    useLax();
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
  <Loader>
    <HeaderTwo />
    <PageTitleConnect
        title={t('con')}
      />
      <ContactFour />
      <CTAThree
        tagline={t('letst')}
        title={t('start')}
        textButton={t('contact_us')}
        linkButton="/connect"
        bg="dark"
      >
        {t('para1')}
      </CTAThree>
    <FooterOne />
  </Loader>
)};

export default Connect;
