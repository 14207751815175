import React, { forwardRef } from 'react';
import HeadingSection1 from '../HeadingSection/HeaderSection1';

const Welcome = forwardRef(( {title, tagline, children}, ref ) => (
    <section ref={ref} className="first-ico-box no-margin-padding-in-bottom" id="about">
          <HeadingSection1 title={title} tagline={tagline}>
          </HeadingSection1>
  </section>
));

export default Welcome;