import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import dataTabs from "../../../data/TabsIconSection/tabs-default-data.json";
import { useTranslation } from "react-i18next";

const AboutTravel = () => 
{
  const { t } = useTranslation();
return(
  <>
  <div className="d-lg-none">
  <div className="white-bg clearfix pt-10 pb-10 pl-20 relative">
    <div className="travel-bg-right bg-flex-right col-md-6"></div>
    <div className="col-about-left col-md-5 offset-md-1 text-left">
      <h2 className="text-uppercase font-700">{t('abo')}</h2>
      <Tabs className="light-tabs mt-50">
        <TabList className="nav nav-tabs text-left" role="tablist">
          {dataTabs
            .filter((v, i) => i < 3)
            .map((tab) => (
              <Tab key={tab.id}>
                <span>{t(tab.title)}</span>
              </Tab>
            ))}
        </TabList>
        <div className="tab-content text-left">
          {dataTabs
            .filter((v, i) => i < 3)
            .map((tab) => (
              <TabPanel key={tab.id} className="tab-pane show in active">
                <p>{t(tab.text)}</p>
              </TabPanel>
            ))}
        </div>
      </Tabs>
    </div>
  </div>
  </div>
  <div className="d-none d-lg-block">
  <div className="white-bg clearfix pt-100 pb-100 relative">
    <div className="travel-bg-right bg-flex-right col-md-6"></div>
    <div className="col-about-left col-md-5 offset-md-1 text-left">
      <h2 className="text-uppercase font-700">{t('abo')}</h2>
      <Tabs className="light-tabs mt-50">
        <TabList className="nav nav-tabs text-left" role="tablist">
          {dataTabs
            .filter((v, i) => i < 3)
            .map((tab) => (
              <Tab key={tab.id}>
                <span>{t(tab.title)}</span>
              </Tab>
            ))}
        </TabList>
        <div className="tab-content text-left">
          {dataTabs
            .filter((v, i) => i < 3)
            .map((tab) => (
              <TabPanel key={tab.id} className="tab-pane show in active">
                <p>{t(tab.text)}</p>
              </TabPanel>
            ))}
        </div>
      </Tabs>
    </div>
  </div>
  </div>
  </>
)};

export default AboutTravel;
