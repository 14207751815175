import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderTwo from "../../components/Header/HeaderTwo";
import { useLax } from "../../helpers/use-lax";
import FooterOne from "../../components/Footer/FooterOne";
import PageTitleSolutions from "../../components/PageTitle/PageTitleSolutions";
import ServicesArchitecture from "../../components/OurServices/ServicesArcitecture2";
import Portfolio from "../../components/Portfolio/Portfolio";
import { useTranslation } from "react-i18next";

const Technology = () => {

  const { t } = useTranslation();

  const service = useRef();
  const feature = useRef();

  useLax();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderTwo />
      <PageTitleSolutions title={t('prod')} />

      <section className="bg-white pt-5">
      <div class="d-lg-none">
        <Portfolio
          filter="false"
          columns="4"
          classAppend="pt-0 white-bg"
          layout="box"
          space="true"
        />
      </div>
      <div class="d-none d-lg-block">
        <Portfolio
          filter="true"
          columns="4"
          classAppend="pt-0 white-bg"
          layout="box"
          space="true"
        />
      </div></section>
      <ServicesArchitecture
        tagline={t('salient')}
      />
      <FooterOne />
    </Loader>
  );
};

export default Technology;
