import React from "react";

const PortfolioItem = ({
  title,
  image,
  space,
  type,
  columns,
}) => {
  return (
    // <ReactWow animation="fadeIn">
      <div
        className={
          "portfolio-item col-md-" + 12/columns +
          (type === "product" ? " portfolio-masonry-item " : " ") +
          (space === "true" ? "gutter-space" : "no-gutter")
        }
      >
        {/* <ReactWow animation="fadeIn"> */}
          <div
            className={
              "portfolio gallery-image-hover "
            }
          >
            <div className="dark-overlay"></div>
            <img src={require("../../assets/images/" + image)} alt="" />
            <div className="portfolio-wrap">
              <br /><br />
              <div className="portfolio-description">
              <a href={"#"} className="links">
                  {title}</a>
              </div>
              
            </div>
          </div>
        {/* </ReactWow> */}
      </div>
    // </ReactWow>
  );
};

export default PortfolioItem;
