import React from "react";
import Slider from "react-slick";
import HeadingSection from "../HeadingSection/HeadingSection";
import teamData from "../../data/Team/team-data11.json";
import { useTranslation } from "react-i18next";

const TeamSlider1 = () => {

  const { t } = useTranslation();

  const settings = {
    autoplay: true,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "owl-carousel blog-slider",
    responsive: [
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section>
        <div className="dn-bg-lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="container">
          <div className="row">
            <HeadingSection title={t('our_team')} tagline={t('what_say')} />
          </div>
          <div className="row mt-50">
            <div className="col-md-12 remove-padding">
              <Slider {...settings}>
                {teamData.map((member) => (
                  <div className="col-md-3 col-sm-6" key={member.id}>
                    <div className="member mt-o">
                      <div className="team-member-container"></div>
                      <div className="card">
                        <h5 className="member-title">{member.quote}</h5>
                        <br />
                        <div className="row">
                          <div className="col">
                            {/* <img
                              src={require("../../assets/images/" +
                                member.image)}
                              className="img-fluid"
                              alt="team-01"
                            /> */}
                            <img
                              className="img-fluid rounded-circle"
                              src={require("../../assets/images/" +
                                member.image)}
                              alt="team-01"
                            />
                          </div>
                          <div className="col">
                            <br />
                            <span className="member-subtitle">
                              {member.name}
                            </span>
                            <br />
                            <p className="member-subtitle">{member.role}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamSlider1;
