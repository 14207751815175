import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import emailjs from 'emailjs-com';

import { useTranslation } from "react-i18next";

const ContactFormSix = () => {

  const { t } = useTranslation();

  const [inputs, setInputs] = useState({});
  const [visable, setVisable] = useState(false);

  function onChange(value) {
    setVisable(true);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_p6ynhk5', 'template_fqin4xd', e.target, 'LvQCA_1hzWU1x8Bof')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      alert("The Email has been sent.");
      setInputs({});
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();
    
  //   const config = {
  //   Host : "smtp.office365.com",
  //   Username : "yashvardhan.agarwal@dahlia.tech",
  //   Password : "Y@3HV$#an",
  //   Port : 587,
  //   To : 'yashvardhan.agarwal@dahlia.tech',
  //   From : 'yashvardhan.agarwal@dahlia.tech',
  //   Subject : "This is the subject new test",
  //   Body : "And this is the body new test"
  //   }
    
  //   if(window.Email){
  //     window.Email.send(config).then(message => alert(message));
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  return (
    <form
      name="contact-form"
      id="contact-form"
      className="mt-50"
      onSubmit={sendEmail}
    >
      <div className="messages"></div>
      <div className="form-floating">
        <input
          type="text"
          name="name"
          className="form-control"
          id="name"
          required="required"
          placeholder="Your Name"
          data-error="Your Name is Required"
          value={inputs.name}
          onChange={handleInputChange}
        />
        <label htmlFor="name">{t('name')}</label>
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div className="form-floating">
        <input
          type="email"
          name="email"
          className="form-control"
          id="email"
          placeholder="Your Email"
          required="required"
          data-error="Please Enter Valid Email"
          value={inputs.email}
          onChange={handleInputChange}
        />
        <label htmlFor="email">{t('email')}</label>
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div className="form-floating">
        <input
          type="phone"
          name="phone"
          className="form-control"
          id="phone"
          placeholder="Your Phone"
          value={inputs.phone}
          onChange={handleInputChange}
        />
        <label htmlFor="phone">{t('phone')}</label>
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div className="form-floating">
        <textarea
          name="message"
          className="form-control"
          id="message"
          rows="7"
          placeholder="Your Message"
          required
          data-error="Please, Leave us a message"
          value={inputs.message}
          onChange={handleInputChange}
        ></textarea>
        <label htmlFor="message">{t('message')}</label>
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div class="d-md-none d-lg-none">
      <ReCAPTCHA
    sitekey="6LemROwkAAAAADWxsEQHqF9mRyCGjn3721FVVL5Q"
    onChange={onChange}
  />
      {visable ? (
        <button type="submit" name="submit" className="btn btn-color btn-circle">
        {t('send_message')}
      </button>
      ) : ("")}
      </div>
      <div class="d-none d-md-block d-lg-block">
      <div className="d-flex justify-content-between align-items-centre">
      <ReCAPTCHA
    sitekey="6LemROwkAAAAADWxsEQHqF9mRyCGjn3721FVVL5Q"
    onChange={onChange}
  />  
      {visable ? (
        <button type="submit" name="submit" className="btn btn-color btn-circle">
        {t('send_message')}
      </button>
      ) : ("")}
      </div>
      </div>
      

      
    </form>
  );
};

export default ContactFormSix;
