import React, { useRef, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import dataArchitecture from "../../data/Slider/architecture-data.json";
import Loader from "./../../components/Loader/Loader";
import HeaderTwo from "../../components/Header/HeaderTwo";
import CTAFour from "../../components/CTA/CTAFour";
import WelcomeTwo from "../../components/WelcomeSection/WelcomeTwo";
import ArchitectureSlider from "../../components/Hero/ArchitectureSlider";
import FunFactsArchitecture from "../../components/FunFacts/FunFactsArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import TestimonialsOne from "../../components/Testimonials/TestimonialsOne";
import Portfolio from "../../components/Portfolio/Portfolio";
import WhoWeAreThree from "../../components/WhoWeAre/WhoWeAreThree";
import BlogSlider from "../../components/Blog/BlogSlider";
import TeamSlider1 from "../../components/Blog/TeamSlider1";
import { useTranslation } from "react-i18next";

const Architecture = () => {

  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [toggler, setToggler] = useState(false);

  const handleToggler = () => {
    setToggler(!toggler);
  };

  var size = true;

  useEffect(() => {
    size = windowSize.innerWidth >= 775;
    console.log(size);
  }, [windowSize, setWindowSize]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const home = useRef();
  const about = useRef();
  const service = useRef();
  const team = useRef();
  const project = useRef();
  const contact = useRef();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "team":
        team.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "project":
        project.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    size = windowSize.innerWidth >= 775;
  }, []);

  return (
    <Loader>
      {/* <HeaderThree scrollToSection={scrollToSection} /> */}

      <HeaderTwo />

      <ArchitectureSlider data={dataArchitecture} ref={home} />

      <WelcomeTwo toggler={toggler} handleToggler={handleToggler} />

      {/* <CounterOne type="wide" /> */}

      <FunFactsArchitecture title={t("about_us")} tagline={t("something_interesting")}>
        {t('common_language')}
      </FunFactsArchitecture>

      {/* <TabsIconSection title="What We Offer" tagline="We Turn Heads" /> */}

      <WhoWeAreThree />

      <div class="d-lg-none">
        <Portfolio filter="false" columns="4" layout="box" space="true" />
      </div>
      <div class="d-none d-lg-block">
        <Portfolio filter="true" columns="4" layout="box" space="true" />
      </div>

      <CTAFour
        title={t('work_together')}
        tagline={t('inside_the_box_together')}
        textButton={t('partner')}
      >
        {t('boost')}
      </CTAFour>

      <TeamSlider1 />

      {/* <TestimonialsOne title="Testimonials" tagline="Happy clients" /> */}

      {/* <BlogSlider /> */}
      {/* <ServicesArchitecture
        title="OUR SERVICE"
        tagline="What we offer"
        ref={service}
      /> */}

      {/* <TeamArchitecture
        title="OUR TEAM"
        tagline="Creating Places for People"
        ref={team}
      /> */}

      {/* <GalleryArchitecture
        title="OUR PROJECTS"
        tagline="The Hype is Justified"
        ref={project}
      /> */}

      {/* <ContactArchitecture
        title="CONTACT ME"
        tagline="Connecting to The Outside World"
        classAppend="mt-50"
        ref={contact}
      /> */}

      <FooterOne />
    </Loader>
  );
};

export default Architecture;
