import React from "react";
import teamData from "../../data/Team/team-data.json";
import Icofont from "react-icofont";
import Welcome from "../../components/WelcomeSection/Welcome21";
import Portfolio from "../../components/Portfolio/Portfolio3";
import Map from "../../assets/images/map1.png";
import Map2 from "../../assets/images/map2.png";
import { useTranslation } from "react-i18next";

const OurTeamThree = () => 
{
  const { t } = useTranslation();
return(
  <>
    <Welcome title={t('of')} tagline={t('mh')}></Welcome>
    
      <div class="d-lg-none">
        <Portfolio
          filter="false"
          columns="4"
          classAppend="pt-0"
          layout="box"
          space="true"
        />
      </div>
      <div class="d-none d-lg-block">
        <Portfolio
          filter="true"
          columns="4"
          classAppend="pt-0"
          layout="box"
          space="true"
        />
      </div>
  </>
)};

export default OurTeamThree;
