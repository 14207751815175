import React from "react";
import ContactFormSix from "./ContactFormSix";
import Icofont from "react-icofont";
import Map from "../Maps/Map";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const ContactFour = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="contact-us white-bg" id="contact">
        <div className="container">
          <div className="clearfix">
            <div className="bg-flex-right col-md-6 d-none d-lg-block map-section">
              {/* <Map /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3920.064341110016!2d106.71970881397544!3d10.729520962993675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f89959156c9%3A0x2c5cc1c9a9470997!2sMSC-L10-Paragon%20Buildings!5e0!3m2!1sen!2sin!4v1680157012164!5m2!1sen!2sin"
                width="800"
                height="600"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-about-left col-md-6 text-left">
              <h2>{t("git")}</h2>
              <h4 className="text-uppercase"></h4>
              <ContactFormSix />
            </div>
          </div>
        </div>
      </section>
      <section className="p-0">
        <div className="container-fluid">
          <div className="row row-flex">
            <div className="col-md-4">
              <div className="col-inner spacer dark-bg">
                <div className="text-center white-color">
                  <Icofont icon="google-map" className="font-50px white-icon" />
                  <h2>{t('office_address')}</h2>

                  <a
                    target="_blank"
                    href="https://goo.gl/maps/xZmqFGaxkbsAjNGD8"
                  >
                    <p>
                      # 809A Saigon Paragon Building,
                      <br />
                      3 Nguyễn Lương Bằng, Tân Phú, Quận 7,
                      <br />
                      Thành phố Hồ Chí Minh, Vietnam, 700000.
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-inner spacer gradient-bg">
                <div className="text-center white-color">
                  <Icofont icon="email" className="font-50px white-icon" />
                  <h2>{t('email_us')}</h2>
                  <Link
                    to="javascript:void(0)"
                    onClick={() =>
                      (window.location = "mailto:sales-vn@dahlia.tech")
                    }
                  >
                    <p className="mb-0">sales-vn@dahlia.tech</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-inner spacer dark-bg">
                <div className="text-center white-color">
                  <Icofont icon="iphone" className="font-50px white-icon" />
                  <h2>{t('call_us')}</h2>
                  <p className="mb-0">+84 708683407</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFour;
