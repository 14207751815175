import React from 'react';
import dataProgress from "../../data/OurSkills/skills-data.json";
import ProgressBar from "../../elements/ProgressBar";
import { useTranslation } from "react-i18next";

const OurSkillsOne = ({ classAppend, font }) => {
  const { t } = useTranslation();
return (
        dataProgress.filter((v,i) => i < 5).map((bar) => (
        <ProgressBar
          key={bar.id}
          title={t(bar.title)}
          percentage={bar.percentage}
          classAppend = {classAppend ? classAppend : ""}
          font={font}
        />
      ))
)};

export default OurSkillsOne;